<template>
  <v-container fluid class="pt-0">
    <div class="text-h5">菜单列表</div>
    <div class="mt-1 mb-5" style="border-bottom: 1px solid #ddd;"></div>

    <v-row dense>
      <v-col :cols="12" sm="6">
        <v-text-field
          dense
          label="输入菜单标题查询"
          solo
          @change="changeSearch"
        ></v-text-field>
      </v-col>
      <v-col :cols="12" sm="3">
        <v-btn class="primary mr-3" @click="doGet(1)">查询</v-btn>
        <v-btn class="primary" @click="addItem()">新增</v-btn>
      </v-col>
    </v-row>
    <v-card flat min-height="300" tile>
      <v-simple-table v-if="refresh">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">标题</th>
              <th class="text-left">路径</th>
              <th class="text-left">父级菜单ID</th>
              <th class="text-left">用户组</th>
              <th class="text-left">状态</th>
              <th class="text-left">操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-show="table_data.length <= 0">
              <td colspan="4" style="text-align: center">
                暂无数据
              </td>
            </tr>
            <tr
              v-for="(item, index) in table_data"
              :key="item.id"
              :style="index % 2 === 0 ? 'background: rgb(242,242,242)' : ''"
            >
              <td>{{ item.id }}</td>
              <td>{{ item.title }}</td>
              <td>{{ item.path }}</td>
              <td>{{ item.parent }}</td>
              <td>{{ item.groups }}</td>
              <td :class="item.base_status ? '' : 'warning--text'">
                {{ item.base_status ? "启用" : "禁用" }}
              </td>
              <td>
                <v-btn color="primary" small @click="editItem(item.id)"
                  >编辑</v-btn
                >
                <!-- <v-btn style="border: 1px solid #1976D2;margin-left: 3px" text  small color="primary">删除</v-btn>-->
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <div v-if="refresh" class="pagination">
      <v-pagination
        v-if="table_data.length > 0"
        v-model="search.page"
        :length="pageCount"
        @input="(e) => doGet(e)"
      ></v-pagination>
    </div>
  </v-container>
</template>
<script>
import { getMenuList } from "@/api/menus";

export default {
  name: "projectManage",
  mounted() {
    this.doGet();
  },
  methods: {
    doGet(page = this.search.page) {
      getMenuList({ ...this.search, page }).then((res) => {
        this.table_data = res.results;
        this.total = res.count;
        this.refresh = false;
        this.$nextTick(() => {
          this.refresh = true;
        });
      });
    },
    changeSearch(e) {
      // this.search.name = e;
      this.search.title = e;
    },

    editItem(id) {
      this.$router.push({ name: "edit_menu", params: { id } });
    },

    addItem() {
      this.$router.push({ name: "add_menu" });
    },
  },
  data: () => ({
    refresh: true,
    search: {
      name: "",
      title: "",
      parent: "",
      page: 1,
      pagesize: 10,
    },
    total: 0,
    table_data: [],
  }),
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.search.pagesize);
    },
  },
};
</script>
<style lang="stylus" scoped>
.n-title
	font-size 32px

.checkbox >>> .v-label
	font-size 12px
	color #212529
	margin-left -10px !important
	margin-right 10px !important

.pagination
	float: right
</style>
